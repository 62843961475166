import * as React from 'react'
import Layout from '../../components/Layout'
import LinkBar from '../../components/LinkBar'
import TopImageHeading from "../../components/TopImageHeading/TopImageHeading";
import BlockLinkPartial from "../../components/BlockLink/BlockLinkPartial";
import AlertBanner from "../../components/AlertBanner";

import {Helmet} from "react-helmet"
import {StaticImage} from "gatsby-plugin-image"
import {Link} from 'gatsby'

//Modular stylesheet
import {blockLinkBox, topImageSectionImage} from "../../components/styles/mainpages.module.css";
import {
    middleCardImage,
    middleCardImages,
    middleCardText,
    middleCardWrapper
} from "../../components/styles/titlepage.module.css";

//Global stylesheet
import "../../components/styles/global.css"

const transport = () => {
    return(
        <Layout pageTitle={"transport"}>
            <Helmet>
                <meta charSet="UTF-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>

                <title>Transport in Bangbiangyang | Ministry of Tourism Official Website</title>

                <meta name="author" content="aqq.xyz"/>
                <meta name="description" content="Maybe you really, really love airplanes. Maybe you'd rather die. Maybe you'd rather die, but this time - literally.
                There's a hundred ways you can get into and around Bangbiangyang. Learn more about them at the official site for Tourism Bangbiangyang."/>

                <meta property="og:title" content="Transport in Bangbiangyang | Ministry of Tourism Official Website"/>
                <meta property="og:description" content="Maybe you really, really love airplanes. Maybe you'd rather die. Maybe you'd rather die, but this time - literally.
                There's a hundred ways you can get into and around Bangbiangyang. Learn more about them at the official site for Tourism Bangbiangyang."/>
                <meta property="og:url" content="bangbiangyang.aqq.xyz/transport"/>
                <meta property="og:site_name" content="bangbiangyang.aqq.xyz"/>

                <meta name="robots" content="noindex"/>

                <link rel="icon" href="../../static/favicon.ico" />
            </Helmet>

            <LinkBar>
                <Link to="/">Home</Link> > <Link to="/transport">Transport</Link>
            </LinkBar>

            <AlertBanner> </AlertBanner>

            <TopImageHeading title="Transport">
                <StaticImage className={topImageSectionImage} src="https://upload.wikimedia.org/wikipedia/commons/0/09/Central_Japan_Railway_Ku_Ha_211-2.jpg" alt="."/>
            </TopImageHeading>

            <div className="midContentWrapper">
                <div className={middleCardWrapper}>
                    <div className={middleCardText}>
                        <h5 data-sal="slide-up">
                            Your spirit grows a little bigger for each new place you see, each mile you fly, and each step you take.
                        </h5>
                        <p data-sal="slide-up">
                            With four international airports, a plentitude of ports, and convenient facilities for every bodiless transit method known to man, no other
                            country grants the same availability, freedom, and safety of travel as Bangbiangyang. Arrive on a fourteen hour flight and seamlessly disembody for instant
                            transfer to any destination in sight - or take a gentle walk instead. We'll leave no options uncovered.
                        </p>

                        <h3 className="genericHeading" data-sal="slide-up">
                            Ways to enter Bangbiangyang
                        </h3>

                        <div className={blockLinkBox}>
                            <BlockLinkPartial title="Yes" destination="./y">
                                Ayayayayayay
                            </BlockLinkPartial>
                            <BlockLinkPartial title="No" destination="./y">
                                ayayayayayya
                            </BlockLinkPartial>
                        </div>
                    </div>

                    <div className={middleCardImage}>
                        <StaticImage className={middleCardImages} src="https://upload.wikimedia.org/wikipedia/commons/b/b8/JR_Hokkaido_Kiha40_near_Jomon_tunnel_20200811_0918_30358.jpg"
                                     alt="." width={3648} height={5472}/>
                    </div>
                </div>
            </div>

        </Layout>
    )
}

export default transport